import React, { useCallback, useEffect, useState } from 'react';
import { decode, handleADA, stripSpecialChars, text_truncate } from '../../utility/utils';

import Axios from 'axios';
import CarouselArrow from '../common/CarouselNavigationArrow/CarouselArrow';
import DSGProTipsHeader from '../../components/common/SVGIcons/ProTips/DSGProTipsHeader';
import GGProTipsHeader from '../../components/common/SVGIcons/ProTips/GGProTipsHeader';
import PLProTipsHeader from '../../components/common/SVGIcons/ProTips/PLProTipsHeader';
import Slider from 'react-slick';

interface ProTipsCarouselProps {
  storeIdentifierName: string;
}

interface Articles {
  title: string;
  url: string;
  image: string;
  description: string;
}

/**  Carousel Dots customization */
const CustomDot = () => {
  return <div className="slickSlider-customDot" />;
};

const ProTipsCarousel = ({ storeIdentifierName }: ProTipsCarouselProps) => {
  const [articleData, setArticleData] = useState([]);

  const fetchArticles = useCallback(async () => {
    try {
      const URL = 'https://mtools-article-search.dickssportinggoods.com/article-search/v2';
      const term = `${window.location.pathname}${window.location.search}`;
      const params = 'pageSize=8&pageNum=0&shouldProvidePlaceholderResults=true';
      let response = await Axios.get(`${URL}/${storeIdentifierName}?term=${term}&${params}`);
      setArticleData(response.data.responseList || []);
    } catch (e) {
      console.log('Failed loading pro-tips articles');
    }
  }, [setArticleData]);

  useEffect(() => {
    fetchArticles();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => <CustomDot />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ProTipsHeader = () => {
    switch (storeIdentifierName) {
      case 'dsg':
        return <DSGProTipsHeader redirectLink="https://www.dickssportinggoods.com/protips" />;
      case 'gg':
        return <GGProTipsHeader redirectLink="https://www.golfgalaxy.com/blog" />;
      case 'pl':
        return <PLProTipsHeader redirectLink="https://www.publiclands.com/blog" />;
      default:
        return <DSGProTipsHeader redirectLink="https://www.dickssportinggoods.com/protips" />;
    }
  };

  if (articleData && articleData.length > 0) {
    return (
      <div key="protips-container-key" className="protips-container">
        <div className="pro-tips-header hmf-mb-m dsg-flex hmf-justify-content-center">
          <span>{ProTipsHeader()}</span>
        </div>
        <div className="pro-tips-carousel  dsg-flex flex-column align-content-flex-start justify-content-center">
          <Slider {...settings}>
            {articleData.map((article: Articles, idx) => {
              return (
                <div
                  data-em={`plp_protips_${stripSpecialChars(article.title)}`}
                  id={`carousel-item-${idx}`}
                  className="pro-tips-card dsg-flex flex-column"
                  key={`MobileCarouselItem_${idx}`}>
                  <a className="pro-tips-url" href={article.url} target="_blank">
                    <div className="pro-tips-image">
                      <img src={article.image} alt={article.title} width="175" height="117" loading="lazy" />
                    </div>
                    <div className="pro-tips-details dsg-flex flex-column ">
                      {' '}
                      <h2 className="pro-tips-title">{decode(article.title)}</h2>
                      <span className="pro-tips-summary">
                        {text_truncate(article.description, 120)}
                      </span>
                    </div>
                  </a>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }

  return null;
};

export default ProTipsCarousel;
