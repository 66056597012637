import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class PLProTipsHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href={this.props.redirectLink}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="323"
          height="32"
          viewBox="0 0 323 32"
          role="Public Lands header image"
          fill="none">
          <path
            d="M21.1926 0H18.9844C18.9844 0 13.2408 15.8078 0.460938 15.8078V31.0073C11.3623 31.0073 16.7993 15.3581 16.7993 15.3581H18.1317C18.1317 24.7194 13.8043 30.4195 12.7519 32H27.4258C26.3316 30.3927 22.0466 24.6777 22.0466 15.3581H23.3789C23.3789 15.3581 28.816 31.0073 39.7173 31.0073V15.8078C26.838 15.8078 21.1926 0 21.1926 0Z"
            fill="#002A19"
          />
          <path
            d="M55.7168 27.4078V4.52778H77.5728V9.93578H63.9088V14.3838H75.7808V19.6638H63.9088V27.4078H55.7168Z"
            fill="#191F1C"
          />
          <path d="M82.0948 27.4078V4.52778H90.3828V27.4078H82.0948Z" fill="#191F1C" />
          <path
            d="M95.998 27.4078V4.52778H118.398V9.80778H104.126V13.2958H116.382V18.4158H104.126V22.1278H119.07V27.4078H95.998Z"
            fill="#191F1C"
          />
          <path
            d="M123.592 27.4078V4.52778H131.784V21.9678H143.592V27.4078H123.592Z"
            fill="#191F1C"
          />
          <path
            d="M147.842 27.4078V4.52778H160.002C170.466 4.52778 175.778 8.49578 175.778 15.6958V15.9198C175.778 23.1518 170.402 27.4078 160.002 27.4078H147.842ZM156.034 21.9998H159.682C164.994 21.9998 167.266 20.1438 167.266 16.0478V15.7918C167.266 11.5358 164.994 9.90378 159.65 9.90378H156.034V21.9998Z"
            fill="#191F1C"
          />
          <path
            d="M202.618 27.8238C192.378 27.8238 186.81 22.9918 186.81 16.1118V15.8558C186.81 8.87978 193.562 4.17578 202.618 4.17578C209.85 4.17578 216.218 6.60778 216.858 12.5918H208.73C208.282 10.7358 206.17 9.67978 202.682 9.67978C198.138 9.67978 195.322 12.0158 195.322 15.9838V16.2398C195.322 19.7598 197.37 22.5118 203.034 22.5118C207.322 22.5118 209.178 21.2638 209.562 19.5358H203.514V14.5438H217.562V17.5838C217.562 23.6958 212.634 27.8238 202.618 27.8238Z"
            fill="#191F1C"
          />
          <path
            d="M234.743 27.8238C226.263 27.8238 221.463 24.3358 221.463 17.3278V4.52778H229.655V17.1358C229.655 20.4318 230.999 22.2878 234.839 22.2878C238.551 22.2878 239.959 20.4638 239.959 17.0078V4.52778H248.151V17.2638C248.151 24.5598 243.031 27.8238 234.743 27.8238Z"
            fill="#191F1C"
          />
          <path d="M253.532 27.4078V4.52778H261.82V27.4078H253.532Z" fill="#191F1C" />
          <path
            d="M267.436 27.4078V4.52778H279.596C290.06 4.52778 295.372 8.49578 295.372 15.6958V15.9198C295.372 23.1518 289.996 27.4078 279.596 27.4078H267.436ZM275.628 21.9998H279.276C284.588 21.9998 286.86 20.1438 286.86 16.0478V15.7918C286.86 11.5358 284.588 9.90378 279.244 9.90378H275.628V21.9998Z"
            fill="#191F1C"
          />
          <path
            d="M299.467 27.4078V4.52778H321.867V9.80778H307.595V13.2958H319.851V18.4158H307.595V22.1278H322.539V27.4078H299.467Z"
            fill="#191F1C"
          />
        </svg>
      </a>
    );
  }
}

PLProTipsHeader.propTypes = {
  redirectLink: PropTypes.string.isRequired,
};
