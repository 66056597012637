import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class GGProTipsHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href={this.props.redirectLink}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="350"
          height="41"
          viewBox="0 0 350 41"
          role="golf galaxy header image"
          fill="none">
          <path
            d="M4.5407 4.21636H0V1.11201H24.5105V10.6104H21.0818V4.21636H8.01572V15.6144H17.6068V18.6261H8.01572V30.4412H12.8808V33.5456H0V30.4412H4.5407V4.21636Z"
            fill="#191F1C"
          />
          <path
            d="M37.53 30.6265H41.6537V33.5456H30.1166V30.6265H34.194V11.7224H30.1166V8.75706H37.53V12.7881C40.5417 9.6374 41.6537 8.71073 43.7387 8.71073H46.4261V12.2321H43.368C41.4684 12.2321 40.171 12.5564 37.53 14.3634V30.6265Z"
            fill="#191F1C"
          />
          <path
            d="M59.7533 33.5456C57.2049 33.5456 55.7686 33.2212 54.7029 32.1556C53.1276 30.5802 52.6179 28.5879 52.6179 21.1281C52.6179 13.7148 53.1276 11.7224 54.7029 10.1471C55.7686 9.0814 57.2049 8.75706 59.7533 8.75706H65.962C68.5103 8.75706 69.9467 9.0814 71.0123 10.1471C72.5877 11.7224 73.0974 13.7148 73.0974 21.1281C73.0974 28.5879 72.5877 30.5802 71.0123 32.1556C69.9467 33.2212 68.5103 33.5456 65.962 33.5456H59.7533ZM56.0002 21.1281C56.0002 27.8465 56.3709 29.0512 57.1123 29.7925C57.6683 30.3485 58.3633 30.5339 60.1239 30.5339H65.5913C67.352 30.5339 68.047 30.3485 68.603 29.7925C69.3443 29.0512 69.715 27.8465 69.715 21.1281C69.715 14.4561 69.3443 13.2514 68.603 12.5101C68.047 11.9541 67.352 11.7687 65.5913 11.7687H60.1239C58.3633 11.7687 57.6683 11.9541 57.1123 12.5101C56.3709 13.2514 56.0002 14.4561 56.0002 21.1281Z"
            fill="#191F1C"
          />
          <path
            d="M87.8153 8.75706V12.0467C91.0586 9.40573 92.217 8.71073 94.858 8.71073H97.5453C99.8157 8.71073 100.881 9.12773 101.808 10.0081C102.318 10.5641 102.688 11.2591 102.874 12.2321C106.349 9.45206 107.507 8.71073 110.148 8.71073H112.835C115.152 8.71073 116.171 9.12773 117.098 10.0081C118.071 10.9811 118.395 12.3247 118.395 14.9658V30.6265H122.38V33.5456H111.214V30.6265H115.106V15.1511C115.106 13.7148 114.874 12.9734 114.364 12.4174C113.855 11.9077 113.345 11.7224 111.538 11.7224H109.175C107.136 11.7224 105.654 12.2784 103.059 13.9464C103.105 14.2708 103.105 14.5951 103.105 14.9658V30.6265H106.997V33.5456H95.9237V30.6265H99.7694V15.1511C99.7694 13.7148 99.584 12.9734 99.028 12.4174C98.5184 11.9077 98.0087 11.7224 96.2017 11.7224H93.885C91.8 11.7224 90.41 12.2784 87.8153 13.9464V30.6265H91.661V33.5456H80.4019V30.6265H84.4793V11.7224H80.4019V8.75706H87.8153Z"
            fill="#191F1C"
          />
          <path
            d="M157.076 30.4412H161.848V33.5456H148.782V30.4412H153.555V4.21636H145.076V11.4444H141.647V1.11201H168.984V11.4444H165.555V4.21636H157.076V30.4412Z"
            fill="#191F1C"
          />
          <path
            d="M182.509 0.185335V12.0467C185.799 9.40573 186.957 8.71073 189.552 8.71073H192.471C194.741 8.71073 195.807 9.12773 196.733 10.0081C197.66 10.9811 198.031 12.3247 198.031 14.9658V30.6265H202.015V33.5456H190.803V30.6265H194.695V15.1511C194.695 13.7148 194.509 12.9734 193.953 12.4174C193.444 11.9077 192.934 11.7224 191.127 11.7224H188.579C186.54 11.7224 185.104 12.2784 182.509 13.9464V30.6265H186.401V33.5456H175.096V30.6265H179.173V3.15069H175.096V0.185335H182.509Z"
            fill="#191F1C"
          />
          <path
            d="M227.467 22.3792H211.667C211.714 28.0319 212.084 29.1902 212.779 29.8389C213.335 30.4412 213.984 30.5802 215.745 30.5802H221.166C222.741 30.5802 223.343 30.3485 223.899 29.8389C224.409 29.2829 224.641 28.4952 224.733 25.9468H228.023C227.931 29.7462 227.514 31.0435 226.309 32.2482C225.382 33.1749 224.224 33.5456 221.814 33.5456H215.42C212.872 33.5456 211.436 33.2212 210.37 32.1556C208.795 30.5802 208.285 28.5879 208.285 21.1281C208.285 13.7148 208.795 11.7224 210.37 10.1471C211.436 9.0814 212.872 8.75706 215.42 8.75706H220.888C223.39 8.75706 224.919 9.12773 225.892 10.1007C227.514 11.7224 228.023 13.8538 228.023 20.9891V21.8231C228.023 22.1938 227.884 22.3792 227.467 22.3792ZM212.733 12.4638C212.084 13.1588 211.714 14.3634 211.667 19.5991H224.641C224.641 14.5024 224.224 13.1588 223.529 12.4638C223.019 11.9077 222.324 11.7224 220.563 11.7224H215.745C213.984 11.7224 213.335 11.9077 212.733 12.4638Z"
            fill="#191F1C"
          />
          <path
            d="M264.841 30.4412H269.613V33.5456H256.547V30.4412H261.32V4.21636H252.841V11.4444H249.412V1.11201H276.749V11.4444H273.32V4.21636H264.841V30.4412Z"
            fill="#191F1C"
          />
          <path
            d="M285.68 4.44803V0H289.664V4.44803H285.68ZM293.51 33.5456H282.019V30.6265H286.189V11.7224H282.019V8.75706H289.525V30.6265H293.51V33.5456Z"
            fill="#191F1C"
          />
          <path
            d="M322.916 21.1281C322.916 28.6342 322.406 30.5802 320.97 32.0629C319.904 33.1286 318.468 33.5919 315.688 33.5919H313.093C310.545 33.5919 309.34 32.9432 306.05 30.2559V37.5766H310.359V40.4956H298.637V37.5766H302.714V11.7224H298.637V8.75706H306.05V12.0467C309.34 9.3594 310.545 8.71073 313.093 8.71073H315.688C318.468 8.71073 319.904 9.17406 320.97 10.2397C322.406 11.7224 322.916 13.6684 322.916 21.1281ZM312.12 11.7224C309.989 11.7224 308.506 12.3711 306.05 13.9464V28.3099C308.599 29.9315 310.081 30.5802 312.259 30.5802H314.993C316.985 30.5802 317.773 30.2559 318.329 29.6999C319.255 28.7732 319.487 27.2905 319.487 21.1281C319.487 15.0121 319.255 13.4831 318.329 12.6028C317.773 12.0004 316.985 11.7224 314.993 11.7224H312.12Z"
            fill="#191F1C"
          />
          <path
            d="M331.42 15.5681C331.42 12.4638 331.93 10.8884 332.949 9.86907C333.737 9.0814 334.756 8.75706 336.61 8.75706H344.44C346.432 8.75706 347.544 9.12773 348.239 9.82273C349.212 10.7957 349.629 11.9541 349.676 16.0778H346.525C346.432 13.1588 346.201 12.6028 345.784 12.1857C345.459 11.8614 344.903 11.6297 343.699 11.6297H337.351C336.332 11.6297 335.776 11.7687 335.405 12.1394C334.942 12.6028 334.664 13.2514 334.664 15.3828C334.664 17.3751 334.803 17.9775 335.173 18.3945C335.59 18.7651 336.007 18.9505 337.629 19.0895L344.857 19.8771C347.081 20.1088 348.147 20.4331 348.795 21.0818C349.722 22.0085 350 23.4448 350 26.5492C350 29.9315 349.49 31.3679 348.471 32.4336C347.683 33.2212 346.618 33.5456 344.811 33.5456H336.471C334.571 33.5456 333.227 33.1749 332.532 32.4799C331.467 31.4142 331.142 30.3949 331.096 26.0395H334.247C334.339 29.0512 334.478 29.6999 334.942 30.1632C335.266 30.4875 335.776 30.6729 337.073 30.6729H344.023C345.135 30.6729 345.691 30.4875 346.062 30.1169C346.479 29.6999 346.757 29.0975 346.757 26.7345C346.757 24.6032 346.525 23.9545 346.154 23.5838C345.83 23.2595 345.367 23.0742 343.791 22.8888L336.656 22.1475C334.386 21.9158 333.366 21.5915 332.671 20.8965C331.745 19.9698 331.42 18.7651 331.42 15.5681Z"
            fill="#191F1C"
          />
        </svg>
      </a>
    );
  }
}

GGProTipsHeader.propTypes = {
  redirectLink: PropTypes.string.isRequired,
};
