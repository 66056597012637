import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class DSGProTipsHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href={this.props.redirectLink}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="326"
          height="40"
          viewBox="0 0 326 40"
          role="Dicks sporting goods header image"
          fill="none">
          <g clip-path="url(#clip0_6246_115737)">
            <path
              d="M14.4891 32.9687V7.03125H9.12451V32.9687H14.4891ZM0.374512 40V0H18.2913L23.2391 4.94792V35.052L18.2913 40H0.374512ZM28.3433 0H37.0933V40H28.3433V0ZM64.9058 14.2969H56.1558V7.03125H50.9214V32.9687H56.1558V24.948H64.9058V35.052L59.9318 40H47.1453L42.1714 35.052V4.94792L47.1453 0H59.9318L64.9058 4.94792V14.2969ZM78.3953 40H69.6453V0H78.3953V16.224H78.6558L83.9163 0H92.8484L86.3381 18.9323L93.1089 40H83.8641L78.6558 23.2552H78.3953V40ZM103.005 0V7.6823L99.5413 12.8646H96.5984L98.7339 7.6823H96.5984V0H103.005ZM107.276 26.4583H116.026V32.9687H121.468V25.4427L111.651 22.1875L107.276 17.5V4.94792L112.25 0H124.984L129.958 4.94792V13.2812H121.208V7.03125H115.817V14.2969L125.635 17.5L130.01 22.1875V35.052L125.036 40H112.25L107.276 35.052V26.4583Z"
              fill="#008D75"
            />
            <path
              d="M158.02 19.5313V7.03125H152.76V19.5313H158.02ZM152.76 26.6147V40H144.01V0H161.822L166.77 4.94792V21.6407L161.822 26.6147H152.76ZM180.209 40H171.459V0H189.322L194.27 4.94792V17.1093L190.547 20.8593L194.27 24.5833V40H185.52V24.1667H180.209V40ZM185.52 17.552V7.03125H180.209V17.552H185.52ZM213.332 32.9687V7.03125H207.785V32.9687H213.332ZM204.01 40L199.035 35.052V4.94792L204.01 0H217.135L222.082 4.94792V35.052L217.135 40H204.01ZM242.447 40V7.47397H236.015V0H257.604V7.47397H251.197V23.737V40H242.447ZM261.822 0H270.572V40H261.822V0ZM289.947 19.5313V7.03125H284.687V19.5313H289.947ZM284.687 26.6147V40H275.937V0H293.749L298.697 4.94792V21.6407L293.749 26.6147H284.687ZM302.89 26.4583H311.64V32.9687H317.082V25.4427L307.265 22.1875L302.89 17.5V4.94792L307.864 0H320.599L325.572 4.94792V13.2812H316.822V7.03125H311.432V14.2969L321.249 17.5L325.625 22.1875V35.052L320.65 40H307.864L302.89 35.052V26.4583Z"
              fill="#191F1C"
            />
          </g>
          <defs>
            <clipPath id="clip0_6246_115737">
              <rect width="325.251" height="40" fill="white" transform="translate(0.374512)" />
            </clipPath>
          </defs>
        </svg>
      </a>
    );
  }
}

DSGProTipsHeader.propTypes = {
  redirectLink: PropTypes.string.isRequired,
};
